.dashboardIcons{
    display: flex;
    width: 35px;
    height: 33px;
    text-align: center;
    background: green;
    align-items: center;
    align-items: center;
    padding: 9px;
    color: white;
    font-size: 18px;
    border-radius: 5px;
}
.notificationlisting{
        padding: 9px;
    font-size: 13px;
    border-radius: 12px;
}